import React, {FunctionComponent} from 'react'
import {graphql}                  from 'gatsby'
import Layout                     from '../components/shared/layout'
import {Grid, Theme, Typography}  from '@material-ui/core'
import theme                      from '../theme'
import {makeStyles}               from '@material-ui/core/styles'
import {GatsbyImage}              from "gatsby-plugin-image";
import Container                  from '@material-ui/core/Container'
import PortableText               from '../components/portableText'
import {ContactDetails}           from '../../entities/clerk'
import CallIcon                   from '@material-ui/icons/Call'
import EmailIcon                  from '@material-ui/icons/Email'
import TwitterIcon                from '@material-ui/icons/Twitter'
import LinkedInIcon               from '@material-ui/icons/LinkedIn'
import PictureAsPdfIcon           from '@material-ui/icons/PictureAsPdf'

export const query = graphql`
    query ClerkQuery($id: String!) {
        placeholderImage: sanitySiteSettings {
            siteMedia {
                peoplePlaceholder {
                    asset {
                        gatsbyImageData
                    }
                }
            }
        }
        clerk: sanityClerk(_id: { eq: $id }) {
            firstName
            lastName
            preferredName
            jobTitle
            memberships
            contactDetails {
                email
                fax
                linkedinProfile
                telephone
                twitterHandle
            }
            slug {
                current
            }
            resume {
                asset {
                    url
                }
            }
            _rawBiography(resolveReferences: {maxDepth: 10})
            clerkPortrait {
                crop {
                    bottom
                    top
                    left
                    right
                }
                hotspot {
                    height
                    width
                    x
                    y
                }
                asset {
                    gatsbyImageData
                }
            }
        }
    }
`
export type Slug = { current: string }

export type Clerk = {
    firstName: string
    lastName: string
    preferredName: string
    jobTitle: string
    _rawBiography: any[]
    clerkPortrait: { asset: { fluid: any }, crop: any, hotspot: any }
    slug: Slug
    contactDetails: ContactDetails
    resume: { asset: { url: string } }
    memberships: string[]
}


interface ClerkProfilePageProps {
    theme: Theme
    data: { clerk: Clerk }
}

const useStyles = makeStyles((theme: Theme) => ({

    contactDetailsLink: {
        fontSize: '1rem',
        textDecoration: 'none',
        color: '#000',
        transition: '0.5s',
        display: 'inline-grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '2rem',
        placeItems: 'center',
        '&:hover': {
            color: 'goldenrod'
        }
    },
    contactDetailsContainer: {
        padding: '1rem 0 0 0'

    },

    clerkProfileContainer: {
        backgroundColor: '#FFF',
        padding: theme.spacing(3)
    },
    profileImageContainer: {
        background: '#333',
        [theme.breakpoints.down('sm')]: {}
    },
    nameTitle: {
        fontWeight: 500
    },
    profileImage: {
        [theme.breakpoints.down('sm')]: {
            minWidth: '320px',
            maxHeight: '850px'
        }
    },
    biographyContainer: {
        maxWidth: '600px'
    }
}), {index: 1})

const ClerkProfilePage: FunctionComponent<ClerkProfilePageProps> = (props: ClerkProfilePageProps) => {
    const classes = useStyles(theme)
    const clerk = props.data.clerk

    const renderContactButtons = (details: ContactDetails) => {

        let telephone, email, twitterHandle, linkedinProfile

        if (details) {
            telephone = details.telephone || ''
            email = details.email || ''
            twitterHandle = details.twitterHandle || ''
            linkedinProfile = details.linkedinProfile || ''
        }

        return (
            <Grid className={classes.contactDetailsContainer}
                  container
                  justify={'flex-start'}
                  alignContent={'center'}
                  spacing={2}
            >
                {
                    telephone && (
                                  <Grid item xs={12} sm={6}>
                                      <a href={`tel:${telephone}`} className={classes.contactDetailsLink}>
                                          <CallIcon fontSize={'large'}/>
                                          {telephone}
                                      </a>
                                  </Grid>
                              )
                }
                {
                    email && (
                              <Grid item xs={12} sm={6}>
                                  <a href={`mailto:${email}`} target={'_blank'} className={classes.contactDetailsLink}>
                                      <EmailIcon fontSize={'large'}/>
                                      {email}
                                  </a>
                              </Grid>
                          )
                }
                {
                    clerk.resume && (
                                     <Grid item xs={12} sm={!twitterHandle || !linkedinProfile ? 6 : 12}>
                                         <a href={clerk.resume.asset.url} className={classes.contactDetailsLink}>
                                             <PictureAsPdfIcon fontSize={'large'}/>
                                             Download Résumé
                                         </a>
                                     </Grid>
                                 )
                }
                {
                    twitterHandle && (
                                      <Grid item xs={12} sm={6}>
                                          <a href={`https://twitter.com/${twitterHandle}`} target={'_blank'}
                                             className={classes.contactDetailsLink}>
                                              <TwitterIcon fontSize={'large'}/> Twitter
                                          </a>
                                      </Grid>
                                  )
                }
                {
                    linkedinProfile && (
                                        <Grid item xs={12} sm={6}>
                                            <a href={linkedinProfile} className={classes.contactDetailsLink}>
                                                <LinkedInIcon fontSize={'large'}/> LinkedIn
                                            </a>
                                        </Grid>
                                    )
                }
            </Grid>
        )
    }

    return (
        <Layout helmetData={{
            pageTitle: clerk.preferredName,
            metaDescription: `${clerk.preferredName} is a clerk at Level 22 Chambers and can help find the right person to provide legal assistance or advice.`,
            slug: `clerks/${clerk.slug.current}`
        }} backgroundImage={''}>
            <Container maxWidth={'xl'} disableGutters={true}>
                <Grid style={{background: '#FFF'}} container justify={'center'}>
                    <Grid className={classes.profileImageContainer} item sm={12} md={6}>
                        {clerk.clerkPortrait && (
                            <GatsbyImage
                                image={clerk.clerkPortrait.asset.gatsbyImageData}
                                className={classes.profileImage}
                                style={{
                                    position: 'sticky',
                                    top: '-30px'
                                }}
                                alt={`Photograph of ${clerk.preferredName}`}/>
                        )}
                    </Grid>
                    <Grid className={classes.clerkProfileContainer} item sm={12} md={6}>
                        <Container maxWidth={'lg'}>
                            <Grid container>
                                {/* FULL NAME === */}
                                <Grid item xs={12} container alignContent={'center'}>
                                    <Typography variant={'h1'} component={'h1'}>
                                        <span className={classes.nameTitle}>{clerk.preferredName}</span>
                                    </Typography>
                                    <Typography variant={'h3'} component={'p'}>
                                        {clerk.jobTitle}
                                    </Typography>
                                    {renderContactButtons(clerk.contactDetails)}
                                </Grid>
                                <Grid item className={classes.biographyContainer}>
                                    <PortableText blocks={clerk._rawBiography}/>
                                </Grid>

                                {
                                    clerk.memberships.length > 0 && (
                                                                 <Grid item xs={12}>
                                                                     <Typography variant={'h5'} component={'h2'}>
                                                                         Memberships
                                                                     </Typography>
                                                                     <ul>
                                                                         {
                                                                             clerk.memberships && clerk.memberships
                                                                                                   .map((membership, idx) =>
                                                                                                       <li key={idx}>{membership}</li>
                                                                                                   )
                                                                         }
                                                                     </ul>
                                                                 </Grid>
                                                             )
                                }
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default ClerkProfilePage
